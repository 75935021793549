import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { WhatNext } from '../components/global'

const HonorRoll = () => (
  <Layout>
    <SEO title="V.E.T. Honor Roll" />
    <div className="container container-last">
      <h1>V.E.T. Honor Roll</h1>
      <p>
        The V.E.T. Honor Roll recognizes all automotive, truck or heavy equipment service
        technicians who have successfully completed the 60 lesson program and passed the V.E.T.
        Final Exam.
      </p>
      <h2>V.E.T. Honor Roll with Distinction</h2>
      <p>
        A technician's name is placed on the Honor Roll with Distinction after the technician passes
        a written V.E.T. Final Exam consisting of 100 electrical-electronics troubleshooting
        questions and achieves a final grade of at least 75%. He also has successfully completed 4
        hands-on electrical-electronic workshops conducted by Vince Fischelli. These 4 workshops are
        no longer available.
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Date Certified</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>J. B. Glassford</td>
            <td>Sun River, OR</td>
            <td>March 15, 2000</td>
          </tr>
          <tr>
            <td>William E. Weber</td>
            <td>Deltona, FL</td>
            <td>May 2, 2000</td>
          </tr>
          <tr>
            <td>Hector Santa-Cruz</td>
            <td>Huntington Beach, CA</td>
            <td>July 14, 2000</td>
          </tr>
          <tr>
            <td>Mark R. Ramsey</td>
            <td>Gainsville, GA</td>
            <td>June 27, 2002</td>
          </tr>
          <tr>
            <td>Lynn Wimsatt</td>
            <td>Lebanon, KY</td>
            <td>December 16, 2005</td>
          </tr>
          <tr>
            <td>Dale Boyenga</td>
            <td>Eden Prairie, MN</td>
            <td>January 13, 2006</td>
          </tr>
          <tr>
            <td>Eric Dibner</td>
            <td>Southbury, CT</td>
            <td>January 19, 2006</td>
          </tr>
          <tr>
            <td>W. Wondolowski</td>
            <td>Brea, CA</td>
            <td>August 5, 2014</td>
          </tr>
          <tr>
            <td>Michael McNerney</td>
            <td>Helena, MT</td>
            <td>February 2, 2016</td>
          </tr>
          <tr>
            <td>Brett Annear</td>
            <td>Poulsbo</td>
            <td>July 11, 2016</td>
          </tr>
        </tbody>
      </table>
      <h2>V.E.T. Honor Roll</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Date Certified</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Patricio Donastorg</td>
            <td>St. Thomas, Virgin Islands</td>
            <td>March 2, 2000</td>
          </tr>
          <tr>
            <td>Mark Spisak</td>
            <td>Charlotte, NC</td>
            <td>March 13, 2000</td>
          </tr>
          <tr>
            <td>Charles V. Palmer</td>
            <td>Columbus, GA</td>
            <td>March 13, 2000</td>
          </tr>
          <tr>
            <td>Jeffery Pinkston</td>
            <td>Port Lucie, FL</td>
            <td>March 15, 2000</td>
          </tr>
          <tr>
            <td>Jim Palmer</td>
            <td>Stockton, IL</td>
            <td>March 15, 2000</td>
          </tr>
          <tr>
            <td>Brian Whittles</td>
            <td>Burbank, CA</td>
            <td>March 15, 2000</td>
          </tr>
          <tr>
            <td>Thomas Krott</td>
            <td>Cuba, NY</td>
            <td>March 20, 2000</td>
          </tr>
          <tr>
            <td>David Goldberg</td>
            <td>Yeadon, PA</td>
            <td>March 24, 2000</td>
          </tr>
          <tr>
            <td>Filbert Henry</td>
            <td>Bronx, NY</td>
            <td>March 24, 2000</td>
          </tr>
          <tr>
            <td>Arturo R. Macaspac</td>
            <td>Virginia Beach, VA</td>
            <td>March 28, 2000</td>
          </tr>
          <tr>
            <td>Jeffery W. Grieff</td>
            <td>Princeton, IL</td>
            <td>March 30, 2000</td>
          </tr>
          <tr>
            <td>Gordon Johnson</td>
            <td>Arlington, VA</td>
            <td>April 27, 2000</td>
          </tr>
          <tr>
            <td>Charles J. Vleck</td>
            <td>Moon Township, PA</td>
            <td>May 5, 2000</td>
          </tr>
          <tr>
            <td>David Hodge</td>
            <td>Bronx, NY</td>
            <td>June 22, 2000</td>
          </tr>
          <tr>
            <td>Gilbert Castaneda</td>
            <td>Houston, TX</td>
            <td>July 25, 2000</td>
          </tr>
          <tr>
            <td>Mark Ramsey</td>
            <td>Gainesville GA</td>
            <td>Aug. 15, 2000</td>
          </tr>
          <tr>
            <td>Mike Cleary</td>
            <td>Fresno, CA</td>
            <td>Sept. 22, 2000</td>
          </tr>
          <tr>
            <td>Mike Colquitt</td>
            <td>Sebastian, FL</td>
            <td>Sept. 25, 2000</td>
          </tr>
          <tr>
            <td>William Wondolowski</td>
            <td>Brea, CA</td>
            <td>Nov. 8, 2000</td>
          </tr>
          <tr>
            <td>Raymond D. Johnson</td>
            <td>Garner, NC</td>
            <td>Dec. 7, 2000</td>
          </tr>
          <tr>
            <td>Alan Wynn</td>
            <td>Houston, TX</td>
            <td>Feb. 17, 2001</td>
          </tr>
          <tr>
            <td>Mitch Rossa</td>
            <td>Prospect Heights, IL</td>
            <td>May 4, 2001</td>
          </tr>
          <tr>
            <td>Joe Musselwhite</td>
            <td>Kemp, TX</td>
            <td>March 3, 2001</td>
          </tr>
          <tr>
            <td>Bob Taplin</td>
            <td>Mankato, MN</td>
            <td>Dec. 10, 2001</td>
          </tr>
          <tr>
            <td>Jay R. Eddings</td>
            <td>Cedar Rapids, IA</td>
            <td>July 6, 2001</td>
          </tr>
          <tr>
            <td>Larry Kuretsch</td>
            <td>El Campo, TX</td>
            <td>June 1, 2002</td>
          </tr>
          <tr>
            <td>Burlington Moss</td>
            <td>Miramar, FL</td>
            <td>Aug. 2, 2002</td>
          </tr>
          <tr>
            <td>Wayne Gosa</td>
            <td>Americas, GA</td>
            <td>Aug. 15, 2002</td>
          </tr>
          <tr>
            <td>Jeff Starkey</td>
            <td>Zanesville, OH</td>
            <td>Oct. 8, 2002</td>
          </tr>
          <tr>
            <td>Getahun Ayele</td>
            <td>Bellevue, WA</td>
            <td>Dec. 23, 2002</td>
          </tr>
          <tr>
            <td>Aaron L. Young</td>
            <td>Seattle, WA</td>
            <td>July 15, 2003</td>
          </tr>
          <tr>
            <td>George Stoll</td>
            <td>Los Gatos, CA</td>
            <td>July 18, 2003</td>
          </tr>
          <tr>
            <td>Rufus Natawidjaja</td>
            <td>Norcross, GA</td>
            <td>Nov. 4, 2003</td>
          </tr>
          <tr>
            <td>Richard DuPlant</td>
            <td>Houston, TX</td>
            <td>Dec. 15, 2003</td>
          </tr>
          <tr>
            <td>Lynn Wimsatt</td>
            <td>Lebon, KY</td>
            <td>June 28, 2004</td>
          </tr>
          <tr>
            <td>Edwin Alfaro</td>
            <td>Richmond, CA</td>
            <td>Aug. 30, 2004</td>
          </tr>
          <tr>
            <td>Jim Williams</td>
            <td>Cedar, MN</td>
            <td>Feb. 28, 2005</td>
          </tr>
          <tr>
            <td>Philip DelaRosa</td>
            <td>Dallas, TX</td>
            <td>Aug. 14, 2005</td>
          </tr>
          <tr>
            <td>Francisco Arrieta</td>
            <td>Big Stone Gap, VA</td>
            <td>Nov. 15, 2005</td>
          </tr>
          <tr>
            <td>Dhanraj Singh</td>
            <td>Toronto, Canada</td>
            <td>Nov. 28, 2005</td>
          </tr>
          <tr>
            <td>Alfred Chong Lip Khuen</td>
            <td>Singapore</td>
            <td>June 23, 2006</td>
          </tr>
          <tr>
            <td>Shaun O'Neil</td>
            <td>Grand Rapids, MI</td>
            <td>Nov. 23, 2006</td>
          </tr>
          <tr>
            <td>Ishwar Narain</td>
            <td>Wheatly Heights, NY</td>
            <td>Jan. 31, 2007</td>
          </tr>
          <tr>
            <td>Bernardo Salazar</td>
            <td>Kissimmee, FL</td>
            <td>Jan. 31, 2008</td>
          </tr>
          <tr>
            <td>John Dolan</td>
            <td>Albuquerque, NM</td>
            <td>March 27, 2008</td>
          </tr>
          <tr>
            <td>Kenneth A. Archer</td>
            <td>Northeast, MD</td>
            <td>June 18, 2008</td>
          </tr>
          <tr>
            <td>Clive Bush</td>
            <td>Coventry UK</td>
            <td>Nov. 26, 2008</td>
          </tr>
          <tr>
            <td>Desegan Chetty</td>
            <td>Kwa-Zulu Natal, So. Africa</td>
            <td>Feb. 17, 2009</td>
          </tr>
          <tr>
            <td>Charles E. Brown</td>
            <td>Clarksville, TN</td>
            <td>Sept. 17, 2009</td>
          </tr>
          <tr>
            <td>Makhoana Qhotsokoane</td>
            <td>Maseru, Lesotho</td>
            <td>July 14, 2010</td>
          </tr>
          <tr>
            <td>Chad Jessup</td>
            <td>Alturas, CA</td>
            <td>June 30, 2011</td>
          </tr>
          <tr>
            <td>Michael Eilbracht</td>
            <td>Chapaign, IL</td>
            <td>Aug. 8, 2011</td>
          </tr>
          <tr>
            <td>Abbas Tariq</td>
            <td>Ontario, Canada</td>
            <td>Oct. 29, 2011</td>
          </tr>
          <tr>
            <td>Tekola Gedlegiorgis</td>
            <td>Herndon, VA</td>
            <td>Aug. 8, 2012</td>
          </tr>
          <tr>
            <td>Juan J. Salazar</td>
            <td>Martinez, CA</td>
            <td>Oct. 24, 2012</td>
          </tr>
          <tr>
            <td>Gerardo Valdez</td>
            <td>Lynn, MA</td>
            <td>Feb. 1, 2015</td>
          </tr>
          <tr>
            <td>Terrence Martinez</td>
            <td>Belize City</td>
            <td>Feb. 18, 2015</td>
          </tr>
          <tr>
            <td>Raleigh Cook</td>
            <td>Kake, AK</td>
            <td>June 9, 2015</td>
          </tr>
          <tr>
            <td>Joseph Wahrer</td>
            <td>Wapakoneta, OH</td>
            <td>Jan. 5, 2017</td>
          </tr>
          <tr>
            <td>Chris McLain</td>
            <td>Murrieta, CA</td>
            <td>Oct. 18, 2018</td>
          </tr>
          <tr>
            <td>Taurayi R. Sewera</td>
            <td>Zimbabwe, Africa</td>
            <td>Aug. 12, 2019</td>
          </tr>
          <tr>
            <td>Kevin D. Moore</td>
            <td>Manassas, VA</td>
            <td>Aug. 14, 2019</td>
          </tr>
          <tr>
            <td>Raul Beltran Negron</td>
            <td>Puerto Rico</td>
            <td>Oct. 5, 2020</td>
          </tr>
          <tr>
            <td>Nathan Mayse</td>
            <td>Shelby Township, MI</td>
            <td>January 30, 2022</td>
          </tr>
        </tbody>
      </table>
      <WhatNext />
    </div>
  </Layout>
)

export default HonorRoll
